@import "_settings/globalSetting";

.cc-addprod-modal {
    text-align: center;
    color: $black;

    .modal-dialog {
        max-width: none;
        margin: 0.5rem 20px;

        @include media-breakpoint-up(sm) {
            max-width: 559px;
            margin: 1.75rem auto;
        }

        .modal-content {
            border-radius: 0;
            border: 0;

            .modal-header {
                padding: 0 20px;
                padding-top: 50px;
                border-bottom: 0;

                @include media-breakpoint-up(sm) {
                    padding: 0 58px;
                    padding-top: 50px;
                }

                .modal-title {
                    width: 100%;
                    font-size: 20px;
                    font-weight: $primary-font-semibold;
                }

                .close {
                    position: absolute;
                    right: 16px;
                    top: 16px;
                    padding: 20px;
                    opacity: 1;

                    &:hover {
                        opacity: 0.7;
                    }

                    .icon-close-remove {
                        font-size: 21px;
                    }
                }
            }

            .modal-body {
                padding: 15px 20px 23px;
                font-size: 14px;

                @include media-breakpoint-up(sm) {
                    padding: 15px 58px 26px;
                }
            }

            .modal-footer {
                padding: 0 20px;
                padding-bottom: 50px;
                border-top: 0;

                @include media-breakpoint-down(xs) {
                    flex-direction: column;
                }

                @include media-breakpoint-up(sm) {
                    padding: 0 82px;
                    padding-bottom: 50px;
                }

                .cc-button-type-3 {

                    @include media-breakpoint-down(xs) {
                        margin: 0;
                    }

                    @include media-breakpoint-up(sm) {
                        margin-right: 15px;
                    }
                }

                .cc-button-type-1 {

                    @include media-breakpoint-down(xs) {
                        margin: 0;
                        margin-top: 15px;
                    }

                    @include media-breakpoint-up(sm) {
                        margin-left: 15px;
                    }

                    &:active {
                        color:  $white;
                    }
                }
            }
        }
    }
}

.modal {
    .modal-content {
        .modal-header {
            .cc-productLimit {
                &:after {
                    content: '';
                    display: block;
                    width: rem-calc(40);
                    height: 1px;
                    background: $red1;
                    position: absolute;
                    left: 50%;
                    margin-left: rem-calc(-20);
                }
            }
        }
    }
}
